import { useEffect as $, useId as F, useState as R } from "react";
import { jsx as c, jsxs as g, Fragment as P } from "react/jsx-runtime";
import { setLoginFlowState as J } from "@vgno/account";
import { getUTMParams as G } from "@vgno/utils";
import { Button as y } from "@vgno/core";
import { Play as S, Replay as W, Pause as Y, AudioLow as K, AudioOff as Q, AudioOn as X } from "@vgno/icons";
import './assets/index.css';const Z = () => {
  try {
    const e = document.querySelector("#overlay-manifest");
    if (!e || !e.textContent)
      throw new Error("Could not find VGTV overlay manifest");
    return JSON.parse(e.textContent);
  } catch (e) {
    return console.warn("Failed to load VGTV overlay manifest", e), null;
  }
}, tt = () => {
  const e = document.createElement("div");
  e.id = "vgtv-overlay", document.body.appendChild(e);
}, et = (e) => {
  try {
    new URL(e);
  } catch {
    throw new Error(`Invalid stylesheet URL: ${e}`);
  }
  const s = document.createElement("link");
  s.rel = "stylesheet", s.href = e, document.head.appendChild(s);
}, st = () => {
  const e = [
    { src: "https://vgc.no/player/player.next.min.bundled-latest.js" },
    { src: "https://vgc.no/player/player-plugin-skin-vgtv2-latest.js" },
    {
      as: "style",
      src: "https://vgc.no/player/player-skin-vgtv2-latest.css"
    }
  ], s = document.createDocumentFragment();
  for (const { as: n = "script", src: t } of e) {
    const a = document.createElement("link");
    a.href = t, a.rel = "preload", a.as = n, s.appendChild(a);
  }
  document.head.appendChild(s);
}, nt = () => "noModule" in HTMLScriptElement.prototype, ot = () => {
  try {
  } catch {
    return !1;
  }
  return !0;
}, at = (e) => e.dataset.useOverlayPlayer === "true", it = (e) => {
  const s = new URL(e);
  history.pushState({}, "", s.pathname + s.search), dispatchEvent(new PopStateEvent("popstate", { state: {} }));
}, rt = async () => {
  if (!ot() || !nt())
    return;
  const e = Z();
  e && (tt(), et(e["vivi.css"]), await import(
    /* @vite-ignore */
    e["vivi.js"]
  ), st(), document.addEventListener("click", (s) => {
    var t;
    const n = (t = s.target) == null ? void 0 : t.closest(
      "a[href*='https://tv.vg.no/video/']"
    );
    n && at(n) && (s.preventDefault(), it(n.href));
  }));
}, ae = () => ($(() => {
  rt();
}, []), null);
var ct = (e, s) => async ({
  playlistId: n,
  assetIds: t = []
}, a) => {
  if (!t.length && !n)
    return { items: [] };
  const i = new URLSearchParams();
  return n ? i.set("playlistId", n) : i.set("assetId", [...t].sort().join(",")), s && i.set("provider", s), (await e("/items", { ...a, searchParams: i })).json();
}, lt = (e, s) => async ({
  assetId: n,
  playlistId: t
}, a) => {
  const i = new URLSearchParams({ assetId: String(n) });
  return t && i.set("playlistId", t), s && i.set("provider", s), (await e("/related-items", {
    ...a,
    searchParams: i
  })).json();
}, dt = (e, s) => async ({
  playlistId: n
}, t) => {
  const a = new URLSearchParams();
  return a.set("playlistId", n), s && a.set("provider", s), (await e("/playlist", {
    ...t,
    searchParams: a
  })).json();
}, ut = (e, s) => ({
  getPlaylist: dt(e, s),
  getItems: ct(e, s),
  getRelatedItems: lt(e, s)
}), mt = class extends Error {
  constructor(e) {
    const s = e.status || e.status === 0 ? e.status : "", n = e.statusText || "", t = `${s} ${n}`.trim(), a = t ? `status code ${t}` : "an unknown error";
    super(`Request failed with ${a}`), this.name = "HttpError", this.response = e;
  }
}, ft = () => {
  try {
    if (typeof window < "u" && "fetch" in window)
      return fetch;
    if ("fetch" in globalThis)
      return fetch;
  } catch (e) {
    console.error('failed to resolve "fetch"', e);
  }
}, ht = (e) => {
  if (!e)
    return {
      signal: void 0,
      clear: () => {
      }
    };
  const s = new AbortController(), n = setTimeout(() => {
    s.abort();
  }, e), t = () => {
    clearTimeout(n);
  };
  return {
    signal: s.signal,
    clear: t
  };
}, vt = ({
  baseUrl: e,
  timeout: s = 2e3,
  fetch: n = ft()
}) => {
  const t = e.replace(/\/$/, "");
  return async (a, i = {}) => {
    const { searchParams: d, timeout: l = s, ...m } = i, r = ht(l);
    let h = `${t}${a}`;
    if (d) {
      const v = new URLSearchParams(d).toString();
      h += `?${v}`;
    }
    const u = await n(h, { ...m, signal: r.signal });
    if (r.clear(), !u.ok)
      throw new mt(u);
    return u;
  };
}, gt = () => `${window.location.origin}/stories`, pt = (e, s) => {
  let n = `${e}/embed`;
  s && (n += `?provider=${s}`);
  const t = document.createElement("iframe");
  return t.setAttribute("allow", "autoplay; web-share"), t.setAttribute("src", n), t.style.position = "fixed", t.style.left = "0px", t.style.right = "0px", t.style.top = "0px", t.style.bottom = "0px", t.style.display = "none", t.style.width = "100%", t.style.height = "100%", t.style.border = "none", t.style.backgroundColor = "#fff", t.className = "stories-overlay", t;
}, U = (e) => {
  try {
    const { pathname: s } = new URL(e), { groups: n } = s.match(/^\/stories\/(?<id>(\d+))/) || {};
    return parseInt((n == null ? void 0 : n.id) || "", 10) || void 0;
  } catch {
    return;
  }
}, yt = (e) => {
  try {
    const { searchParams: s } = new URL(e);
    return s.get("playlistId") || void 0;
  } catch {
    return;
  }
}, wt = (e) => {
  const s = e.map((n) => yt(n)).filter((n) => !!n);
  if (!(s.length < e.length) && !([...new Set(s)].length > 1))
    return s[0];
}, kt = class extends EventTarget {
  constructor(e = {}) {
    var s, n;
    super(), this.isIframeReady = !1, this.state = "closed", this.baseUrl = (s = e.baseUrl) != null ? s : gt(), this.items = /* @__PURE__ */ new Map(), this.provider = e.provider, this.storiesApi = ut(
      vt({ baseUrl: `${this.baseUrl}/api`, timeout: (n = e.timeout) != null ? n : 1500 }),
      this.provider
    );
  }
  init() {
    if (this.iframe)
      return;
    this.iframe = pt(this.baseUrl, this.provider), document.body.appendChild(this.iframe);
    const e = (s) => {
      try {
        const n = JSON.parse(s.data);
        switch (n.type) {
          case "overlay/go-back": {
            this.dispatch("overlay/go-back");
            break;
          }
          case "overlay/state": {
            this.dispatch("overlay/state", n.payload);
            break;
          }
          case "overlay/ready": {
            this.isIframeReady = !0;
            break;
          }
        }
      } catch {
      }
    };
    window.addEventListener("message", e), this.removeIframeListener = () => {
      window.removeEventListener("message", e);
    };
  }
  destroy() {
    var e;
    (e = this.removeIframeListener) == null || e.call(this), this.state = "closed", this.isIframeReady = !1, this.iframe && (document.body.removeChild(this.iframe), this.iframe = void 0);
  }
  async preloadItem(e) {
    await this.getItem(e);
  }
  async preloadItems(e) {
    const s = e.map((i) => U(i)).filter((i) => !!i).filter((i) => !this.items.has(i)), n = wt(e), t = this.storiesApi.getItems({ playlistId: n, assetIds: s }), a = async (i) => {
      try {
        const { items: d } = await t;
        return d.find((l) => l.type === "asset" && l.asset.id === i);
      } catch (d) {
        console.error(d);
        return;
      }
    };
    s.forEach((i) => {
      this.items.set(i, a(i));
    });
  }
  async openItem(e) {
    const s = this.iframe;
    if (!s)
      return !1;
    const n = await this.getItem(e);
    if (!n)
      return !1;
    const t = this.postMessage({
      type: "overlay/set-item",
      payload: { item: n, url: e }
    });
    return t && (this.dispatch("overlay/open"), this.state = "open", s.style.display = "unset"), t;
  }
  getItem(e) {
    const s = U(e);
    if (!s)
      return;
    let n = this.items.get(s);
    return n || (n = this.fetchItem(s), this.items.set(s, n)), n;
  }
  closeOverlay() {
    this.iframe && this.state === "open" && (this.state = "closed", this.iframe.style.display = "none", this.postMessage({ type: "overlay/close" }), this.dispatch("overlay/close"));
  }
  async fetchItem(e) {
    try {
      const { items: s } = await this.storiesApi.getItems({ assetIds: [e] });
      return s.find((n) => n.type === "asset" && n.asset.id === e);
    } catch (s) {
      console.error(s);
      return;
    }
  }
  postMessage(e) {
    return this.iframe && this.iframe.contentWindow && this.isIframeReady ? (this.iframe.contentWindow.postMessage(JSON.stringify(e), "*"), !0) : !1;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(e, s) {
    const n = s ? new CustomEvent(e, { detail: s }) : new Event(e);
    this.dispatchEvent(n);
  }
};
const bt = () => {
  const { hostname: e } = window.location;
  return e === "stage.vg.no" ? "https://stage.vg.no/stories" : "https://www.vg.no/stories";
}, Pt = () => {
  const e = new kt({ baseUrl: bt() });
  let s = document.title, n;
  return window.addEventListener("popstate", () => {
    const { pathname: t } = new URL(location.href);
    t.match(/\/stories\/\d+/) ? e.openItem(location.href) : e.closeOverlay();
  }), e.addEventListener("overlay/open", () => {
    s = document.title, n = document.body.style.getPropertyValue("overflow"), document.body.style.overflow = "hidden";
  }), e.addEventListener("overlay/close", () => {
    s && (document.title = s), n !== void 0 && (document.body.style.overflow = n), s = "", n = void 0;
  }), e.addEventListener("overlay/state", ({ detail: t }) => {
    const { path: a, title: i } = t;
    window.history.replaceState({}, "", window.location.origin + a), document.title = i;
  }), e.addEventListener("overlay/go-back", () => {
    history.back();
  }), e;
}, _t = () => {
  const e = window.__stories_overlay ?? Pt();
  window.__stories_overlay = e, e.init();
  const s = async (n) => {
    const { pathname: t, search: a } = new URL(n), i = t + a;
    await e.openItem(n) ? history.pushState({}, "", window.location.origin + i) : window.location.href = n;
  };
  document.addEventListener("click", (n) => {
    var a;
    const t = (a = n.target) == null ? void 0 : a.closest(
      "a[href*='https://www.vg.no/stories/']"
    );
    t && (n.preventDefault(), s(t.href));
  });
}, ie = () => ($(() => {
  _t();
}, []), null), re = () => window.__stories_overlay, q = "https://www.vg.no/vgc/player/player.next.min.bundled-latest.js";
let x = !1;
const $t = () => {
  if (!window || x)
    return;
  x = !0;
  const e = document.createElement("script");
  e.src = q, e.async = !0, document.head.appendChild(e);
}, M = (e) => new Promise((s) => {
  window[e] ? s(window[e]) : window.addEventListener("identity-initialized", () => {
    s(window[e]);
  });
}), It = async (e) => {
  const { id: s, assetType: n } = e.rawAsset, t = n === "video" ? `https://api.vg.no/svp/token/v2/${s}?access=plus` : `https://api.vg.no/svp/token/${s}?access=plus`;
  try {
    const a = await fetch(t, {
      credentials: "include"
    });
    if (!a.ok)
      throw new Error("Failed to fetch user status");
    const i = await a.json();
    return { hasProduct: i.hasAccess, loggedIn: i.loggedIn };
  } catch {
    return { hasProduct: !1, loggedIn: !1 };
  }
}, Rt = (e = $t) => {
  let s = [], n = !1;
  const t = (l) => {
    const m = window.SVP.getPlayerConfig(
      {
        site: "vg",
        ads: {
          bumper: !0,
          midroll: !0,
          pausead: !0
        },
        paywallAccess: {
          enabled: !0,
          getSpidIdentityInstance: () => M("Identity"),
          getSpidMonetizationInstance: () => M("SPiD_Monetization"),
          message: {
            utmParameters: G(window.location.href, !0),
            onLoginClick: () => {
              var h, u;
              const r = J();
              (u = window.Identity) == null || u.login({
                redirectUri: (h = window.Identity) == null ? void 0 : h.redirectUri,
                state: r
              });
            }
          }
        },
        ...l.options
      },
      {
        userStatus: It,
        ...l.config
      }
    );
    return new window.SVP.Player(m);
  }, a = () => typeof window.SVP < "u" && !!window.SVP.Player;
  return {
    initLibrary: () => {
      n || (n = !0, window.addEventListener("onSvpPlayerReady", () => {
        s.forEach((l) => l()), s = [];
      }), e());
    },
    loadVideo: (l) => new Promise((m) => {
      a() ? m(t(l)) : s.push(() => m(t(l)));
    })
  };
}, O = Rt(), H = () => {
  if (!(typeof window > "u"))
    return O.initLibrary(), O.loadVideo;
}, St = "_playerContainer_tm6eo_1", Ct = "_podcastContainer_tm6eo_12", V = {
  playerContainer: St,
  podcastContainer: Ct
}, L = {
  audio: "https://vgc.no/player/player-skin-vgtv-latest.css",
  video: "https://vgc.no/player/player-skin-vgtv2-latest.css"
}, Lt = (e) => `https://www.vg.no/nyheter/i/jPJ5g9/vg?utm_content=kjopvgpluss&utm_source=vgno&asset_type=video&asset_id=${e}&asset_provider=vgtv`, ce = ({
  assetId: e,
  assetType: s = "video",
  autoplayMuted: n = !1,
  className: t,
  context: a,
  enableAutoPlay: i = !1,
  loading: d = "lazy",
  loop: l = !1,
  noFirstAd: m = !1,
  videoPreview: r = !1,
  vendor: h = "vgtv",
  ...u
}) => {
  const v = H(), _ = F(), o = `svp-video-${e}-${_}`, f = {
    name: s,
    url: s in L ? L[s] : L.video
  };
  return $(() => {
    const p = {
      context: a,
      noFirstAd: m,
      ui: {
        videoPreview: r
      }
    }, k = {
      autoplay: i,
      id: Number(e),
      mute: n,
      node: o,
      repeat: l,
      settings: {
        na: s === "audio"
        // na = no ads
      },
      skin: f.name === "video" ? void 0 : f,
      subscriptionUrl: Lt(e),
      vendor: h
    };
    if (d === "eager") {
      v == null || v({
        config: k,
        options: p
      });
      return;
    }
    const w = new IntersectionObserver(
      (I) => {
        I[0].isIntersecting && (w.disconnect(), v == null || v({
          config: k,
          options: p
        }));
      },
      {
        rootMargin: "100%"
      }
    );
    return w.observe(document.getElementById(o)), () => w.disconnect();
  }, [e, v, i, n, d, l]), /* @__PURE__ */ c(
    "div",
    {
      ...u,
      className: t || (f.name === "audio" ? V.podcastContainer : V.playerContainer),
      children: /* @__PURE__ */ c("div", { id: o })
    }
  );
}, le = [
  {
    as: "style",
    href: L.video
  },
  {
    as: "script",
    href: q
  }
], Et = "_text_1abvd_1", Nt = {
  text: Et
}, Ut = () => /* @__PURE__ */ c("p", { className: Nt.text, children: "ANNONSE" }), xt = "_duration_uj4c6_1", Mt = {
  duration: xt
}, B = (e) => {
  const s = Math.floor(e / 60), n = Math.floor(e % 60), t = n < 10 ? `0${n}` : n;
  return `${s < 10 ? `0${s}` : s}:${t}`;
}, Ot = ({ currentTime: e, duration: s, className: n }) => /* @__PURE__ */ g(
  "p",
  {
    role: "timer",
    "aria-live": "off",
    className: `label-small font-inter label-secondary ${Mt.duration} ${n}`,
    children: [
      B(e),
      " / ",
      B(s)
    ]
  }
), Vt = "_srOnly_1249o_1", Bt = "_introduction_1249o_13", A = {
  srOnly: Vt,
  introduction: Bt
}, T = (e) => {
  if (e < 60)
    return `${Math.round(e / 10) * 10} sekunder`;
  {
    const s = Math.round(e / 60);
    return `${s} minutt${s === 1 ? "" : "er"}`;
  }
}, j = ({ duration: e }) => /* @__PURE__ */ g(P, { children: [
  /* @__PURE__ */ g("p", { "aria-hidden": "true", className: A.introduction, children: [
    /* @__PURE__ */ c("strong", { children: "Lytt til saken" }),
    !!e && /* @__PURE__ */ g(P, { children: [
      " ",
      /* @__PURE__ */ c("span", { children: "•" }),
      " ",
      /* @__PURE__ */ c("span", { children: T(e) })
    ] })
  ] }),
  /* @__PURE__ */ c("p", { className: A.srOnly, children: !!e && `Avspilling har en varighet på ${T(e)}.` })
] }), At = "_button_vec52_1", b = {
  button: At
}, z = ({
  state: e,
  isApp: s,
  vendor: n,
  assetId: t,
  start: a,
  pause: i
}) => s ? /* @__PURE__ */ c(
  y,
  {
    "data-track-click-intent": "Play",
    "data-track-element-type": "Play Button",
    size: "large",
    style: "filled",
    "aria-label": "Lytt til denne saken",
    href: `audio:${n}:${t}`,
    "data-track-no-utm": !0,
    className: b.button,
    children: /* @__PURE__ */ c(S, {})
  }
) : /* @__PURE__ */ c(P, { children: (() => {
  switch (e) {
    case "buffering":
    case "loading":
    case "stalled":
      return /* @__PURE__ */ c(
        y,
        {
          "data-track-click-intent": "Attempt",
          "data-track-element-type": "Play Button Disabled",
          size: "large",
          style: "filled",
          "aria-disabled": "true",
          "aria-label": "Laster inn lytt til saken avspiller",
          className: b.button,
          children: /* @__PURE__ */ c(S, {})
        }
      );
    case "playing":
    case "adPlaying":
      return /* @__PURE__ */ c(
        y,
        {
          "data-track-click-intent": "Pause",
          "data-track-element-type": "Pause Button",
          size: "large",
          style: "filled",
          "aria-label": "Pause avspilling av saken",
          onClick: () => i(),
          className: b.button,
          children: /* @__PURE__ */ c(Y, {})
        }
      );
    case "complete":
      return /* @__PURE__ */ c(
        y,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Rewatch Button",
          size: "large",
          style: "filled",
          "aria-label": "Start avspilling av saken på nytt",
          onClick: () => a(),
          className: b.button,
          children: /* @__PURE__ */ c(W, {})
        }
      );
    case "idle":
      return /* @__PURE__ */ c(
        y,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Play Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => a(),
          className: b.button,
          children: /* @__PURE__ */ c(S, {})
        }
      );
    default:
      return /* @__PURE__ */ c(
        y,
        {
          "data-track-click-intent": "Resume",
          "data-track-element-type": "Resume Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => a(),
          className: b.button,
          children: /* @__PURE__ */ c(S, {})
        }
      );
  }
})() }), Tt = "_playbackRate_1qo2p_1", jt = {
  playbackRate: Tt
}, E = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3], zt = ({ playbackRate: e, setPlaybackRate: s }) => {
  const n = E[(E.indexOf(e) + 1) % E.length];
  return /* @__PURE__ */ g(
    y,
    {
      className: jt.playbackRate,
      style: "filled",
      size: "small",
      shape: "capsule",
      "aria-label": `Endre avspillingshastighet til ${n}x`,
      onClick: () => s(n),
      "data-track-click-intent": "Update",
      "data-track-element-type": `Playback Rate Button ${n}`,
      children: [
        e,
        "x"
      ]
    }
  );
}, Dt = "_seekSlider_it4ij_1", Ft = {
  seekSlider: Dt
}, D = (e) => {
  const s = Math.floor(e / 60), n = Math.floor(e % 60);
  return `${s} minutter, ${n} sekunder`;
}, qt = ({
  currentTime: e,
  duration: s,
  disableSeeking: n,
  seek: t
}) => {
  const a = e / s * 100;
  return /* @__PURE__ */ c(
    "input",
    {
      className: Ft.seekSlider,
      style: {
        background: `linear-gradient(to right, var(--accent) ${a}%, #ccc ${a}%)`
      },
      onChange: (i) => {
        n || t(Number(i.target.value));
      },
      type: "range",
      id: "seek-slider",
      "aria-label": "Søkelinje",
      "aria-disabled": n,
      "aria-valuetext": `${D(e)} av ${D(s)}`,
      value: e,
      max: s,
      min: 0,
      "data-track-click-intent": "Show",
      "data-track-element-type": "Seek Slider"
    }
  );
}, Ht = "_wrapper_13ik5_1", Jt = "_volumeButton_13ik5_5", Gt = "_volumeSlider_13ik5_13", N = {
  wrapper: Ht,
  volumeButton: Jt,
  volumeSlider: Gt
}, Wt = ({ setVolume: e, volume: s, mute: n, muted: t }) => {
  const a = s < 25 && !t, i = s === 0 || t, d = s >= 25 && !t, l = () => {
    t && s === 0 && e(100), n(!t);
  };
  return /* @__PURE__ */ g("div", { className: N.wrapper, children: [
    /* @__PURE__ */ c(
      "input",
      {
        className: N.volumeSlider,
        style: {
          background: `linear-gradient(to right, var(--accent) ${s}%, #ccc ${s}%)`
        },
        onChange: (m) => e(Number(m.target.value)),
        type: "range",
        id: "volume-slider",
        "aria-label": "Volumkontroll",
        "aria-valuetext": `Volum ${s}%`,
        value: s,
        max: 100,
        min: 0,
        "data-track-click-intent": "Update",
        "data-track-element-type": "Volume Slider"
      }
    ),
    /* @__PURE__ */ g(
      y,
      {
        className: N.volumeButton,
        "aria-label": t ? "Slå på lyd" : "Slå av lyd",
        style: "filled",
        size: "small",
        shape: "capsule",
        onClick: l,
        "data-track-click-intent": "Update",
        "data-track-element-type": t ? "Volume On" : "Volume Off",
        children: [
          a && /* @__PURE__ */ c(K, {}),
          i && /* @__PURE__ */ c(Q, {}),
          d && /* @__PURE__ */ c(X, {})
        ]
      }
    )
  ] });
}, Yt = "_svpContainer_8u983_1", Kt = "_controls_8u983_5", Qt = "_durationInfo_8u983_11", Xt = "_duration_8u983_11", C = {
  svpContainer: Yt,
  controls: Kt,
  durationInfo: Qt,
  duration: Xt
}, de = ({
  assetId: e,
  vendor: s = "vgtv",
  isApp: n
}) => {
  const [t, a] = R(null), [i, d] = R(!1), [l, m] = R(!1), [r, h] = R(null), u = H(), v = F(), _ = `svp-video-${e}-${v}`;
  return $(() => {
    let o = null;
    return (async () => {
      const p = await (u == null ? void 0 : u({
        config: {
          vendor: s,
          node: _,
          autoplay: !1,
          id: Number(e),
          mute: !1,
          repeat: !1,
          locale: "no"
        }
      }));
      p && (o = p, a(p));
    })(), () => {
      o && o.remove();
    };
  }, [e, u, _, s]), $(() => {
    const o = (k = !1) => {
      var I;
      if (!k && !i) return;
      const w = ((I = t == null ? void 0 : t.getRawAsset()) == null ? void 0 : I.duration) ?? 0;
      h({
        currentTime: (t == null ? void 0 : t.getCurrentTime()) ?? 0,
        duration: (t == null ? void 0 : t.getDuration()) || w / 1e3,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, f = (k, w) => {
      h({
        currentTime: k,
        duration: w,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, p = () => {
      m(!0), o();
    };
    return t == null || t.on("assetPlay", o), t == null || t.on("assetReady", o), t == null || t.on("complete", o), t == null || t.on("error", o), t == null || t.on("mute", o), t == null || t.on("pause", o), t == null || t.on("play", o), t == null || t.on("playNext", o), t == null || t.on("volume", o), t == null || t.on("adFinished", o), t == null || t.on("adPause", o), t == null || t.on("adPlay", o), t == null || t.on("adStarted", o), t == null || t.on("playbackRateChanged", () => console.log("playbackRateChanged")), t == null || t.on("ready", () => {
      d(!0), o(!0);
    }), t == null || t.on("initialPlay", p), t == null || t.on("adProgress", f), t == null || t.on("time", f), () => {
      t == null || t.off("assetPlay", o), t == null || t.off("assetReady", o), t == null || t.off("complete", o), t == null || t.off("error", o), t == null || t.off("initialPlay", p), t == null || t.off("mute", o), t == null || t.off("pause", o), t == null || t.off("play", o), t == null || t.off("playNext", o), t == null || t.off("ready", o), t == null || t.off("volume", o), t == null || t.off("time", f), t == null || t.off("adProgress", f), t == null || t.off("adFinished", o), t == null || t.off("adPause", o), t == null || t.off("adPlay", o), t == null || t.off("adStarted", o), t == null || t.off("adProgress", f);
    };
  }, [t, i]), /* @__PURE__ */ g(P, { children: [
    /* @__PURE__ */ c("div", { className: C.svpContainer, children: /* @__PURE__ */ c("div", { id: _ }) }),
    /* @__PURE__ */ c("div", { className: C.controls, children: i && !n ? /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ c(
        z,
        {
          state: (r == null ? void 0 : r.state) ?? "idle",
          start: () => t == null ? void 0 : t.play(),
          pause: () => t == null ? void 0 : t.pause()
        }
      ),
      l ? /* @__PURE__ */ g("div", { className: C.durationInfo, children: [
        /* @__PURE__ */ c(
          qt,
          {
            disableSeeking: (r == null ? void 0 : r.state) === "adPlaying",
            currentTime: (r == null ? void 0 : r.currentTime) ?? 0,
            duration: (r == null ? void 0 : r.duration) ?? 0,
            seek: (o) => t == null ? void 0 : t.seek(o)
          }
        ),
        (r == null ? void 0 : r.state) === "adPlaying" && /* @__PURE__ */ c(Ut, {}),
        /* @__PURE__ */ c(
          Ot,
          {
            className: C.duration,
            currentTime: (r == null ? void 0 : r.currentTime) ?? 0,
            duration: (r == null ? void 0 : r.duration) ?? 0
          }
        )
      ] }) : /* @__PURE__ */ c(j, { duration: (r == null ? void 0 : r.duration) || 0 }),
      /* @__PURE__ */ c(
        Wt,
        {
          muted: (r == null ? void 0 : r.isMuted) ?? !1,
          mute: (o) => t == null ? void 0 : t.setMute(o),
          volume: (r == null ? void 0 : r.volume) ?? 100,
          setVolume: (o) => t == null ? void 0 : t.setVolume(o)
        }
      ),
      /* @__PURE__ */ c(
        zt,
        {
          playbackRate: (r == null ? void 0 : r.playbackRate) ?? 1,
          setPlaybackRate: (o) => {
            h((f) => ({
              ...f,
              playbackRate: o
            })), t == null || t.setPlaybackRate(o);
          }
        }
      )
    ] }) : /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ c(
        z,
        {
          isApp: n,
          vendor: s,
          assetId: e,
          state: "loading",
          start: () => {
          },
          pause: () => {
          }
        }
      ),
      /* @__PURE__ */ c(j, { duration: (r == null ? void 0 : r.duration) || 0 })
    ] }) })
  ] });
};
export {
  ae as OverlayPlayer,
  ie as StoriesOverlay,
  de as TTSPlayer,
  ce as Video,
  re as getStoriesOverlay,
  it as openOverlay,
  le as preloadAssets
};
